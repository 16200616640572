import { device } from "src/styles/breakpoints"
import * as newColors from 'src/styles/newColors'
import styled from "styled-components"

export const Card = styled.article`
  background-color: ${newColors.primary[500]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  width: 279px;
  height: 249px;
  padding: 32px 36px;

  @media ${device.tablet}{
    width: 308px;
    height: 254px;
    padding: 24px;
  }

  @media ${device.desktopLG}{
    width: 338.6px;
    height: 308px;
    padding: 32px 36px;
  }
  
  @media ${device.desktopXXL}{
    width: 384px;
  }

  .card{
    &__icon{
      margin-bottom: 24px;

      @media ${device.desktopLG}{
        margin-bottom: 10px;
      }      
      
      @media ${device.desktopXXL}{
        margin-bottom: 32px;
      }
    }
    &__title{
      font-family: "Citrina", Helvetica, sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 28.8px;
      color: white;
      margin-bottom: 16px;
      letter-spacing: 0;

      @media ${device.tablet}{
        font-size: 28px;
        line-height: 33.6px;
      }
      
      @media ${device.desktopLG}{
        font-size: 40px;
        line-height: 44px;
      }

    }

    &__description, &__links{
      font-family: "Inter", Helvetica, sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16.94px;
      color: white;
      letter-spacing: 0;      
    }
    
    &__description{
      margin-bottom: 16px;


      @media ${device.tablet}{
        font-size: 16px;
        line-height: 19.36px;
      }

      @media ${device.desktopLG}{
        font-size: 18px;
        line-height: 21.8px;
      }
    }
    &__links{


      @media ${device.tablet}{
        font-size: 16px;
        line-height: 19.36px;
      }

      @media ${device.desktopLG}{
        font-size: 18px;
        line-height: 21.8px;
      }
    }
  }
`
