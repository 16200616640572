import { OrangeCardProps } from "./components/OrangeCard/_index"

export type CardsInfosProps = OrangeCardProps & {
  redirectUrl?: string;
}

export const cardsInfos: CardsInfosProps[] = [
  {
    title: 'Imprensa',
    description: 'Envie uma mensagem para a nossa assessoria de imprensa:',
    downloadOrEmail: 'imprensa@bancointer.com.br <br> inter@loures.com.br',
    icon: {
      icon: 'ic_message',
      lib: 'interco',
      className: 'className',
      color: 'white',
      customPath: 'media-communication/',
    },
  },
  {
    title: 'Relatorio Anual',
    description: 'Tudo sobre as ações e avanços que fizeram o Inter continuar crescendo em 2023.',
    downloadOrEmail: 'Saiba mais',
    redirectUrl: 'https://api.mziq.com/mzfilemanager/v2/d/b4dc0b14-a83a-40a9-9545-d9e4f18ed7af/7f1035ec-6f3f-7b5e-1b8b-d7c553302448?origin=2',
    icon: {
      icon: 'ic_document_blank',
      lib: 'interco',
      className: 'className',
      color: 'white',
      customPath: 'files/',
    },
  },
  {
    title: 'Investidores',
    description: 'Para resultados, governança corporativa e arquivos regulatórios.',
    downloadOrEmail: 'Saiba Mais',
    redirectUrl: 'https://investors.inter.co/',
    icon: {
      icon: 'ic_trending_up',
      lib: 'interco',
      className: 'className',
      color: 'white',
      customPath: 'finance/',
    },
  },
  {
    title: 'Patrocínios',
    description: 'Envie a sua proposta para a nossa equipe',
    downloadOrEmail: 'patrocinios@bancointer.com.br',
    icon: {
      icon: 'ic_handshake',
      lib: 'interco',
      className: 'className',
      color: 'white',
      customPath: 'human/',
    },
  },
  {
    title: 'Materiais de marca',
    description: 'Baixe materiais de marca do Inter, caso precise utilizar para divulgações.',
    downloadOrEmail: 'Download',
    redirectUrl: 'https://static.bancointer.com.br/press-kit/press-kit.zip',
    icon: {
      icon: 'ic_arrow_download',
      lib: 'interco',
      className: 'className',
      color: 'white',
      customPath: 'action-navigation/',
    },
  },
]
