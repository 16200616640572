import { device } from "src/styles/breakpoints"
import * as newColors from 'src/styles/newColors'
import styled from "styled-components"

export const ContentWrapper = styled.section`
  padding: 52px 0 40px 0;
  background-color: ${newColors.primary[500]};

  @media ${device.tablet}{
    padding: 56px 36px;
  }

  @media ${device.desktopLG}{
    padding: 80px 44px 64px 44px;
  }

  @media ${device.desktopXL}{
    padding: 148px 144px 96px 144px;
  }

  h1,h2,h3,h4,h5,h6{
    font-family: 'Citrina', Helvetica, sans-serif;
    font-weight: 500;

    padding: 0;
    margin: 0;
  }
  
  p,span{
    font-family: 'Inter', Helvetica, sans-serif;
    font-weight: 400;

    padding: 0;
    margin: 0;
  }

  .content_wrapper{
    &__texts{
      &__title{
        color: white;
        font-size: 56px;
        line-height: 61.6px;
        margin-bottom: 32px;

        @media ${device.tablet}{
          font-size: 72px;
          line-height: 79.2px;
          letter-spacing: 0;
        }

        @media ${device.desktopLG}{
          font-size: 80px;
          line-height: 88px;
        }

        @media ${device.desktopXL}{
          margin-bottom: 40px;
        }
      }
      &__description{
        color: white;
        font-size: 16px;
        line-height: 19.36px;
        letter-spacing: 0;

        @media ${device.desktopLG}{
          font-size: 18px;
          line-height: 21.8px;
        }
      }
    }
}
`
